import { isEmpty, isObject, isString } from 'lodash';
import { Insurable } from '../models/insurable.model';
import { SmartyAddress } from '../models/address.model';

export const smartyAddressToString = (address: SmartyAddress): string => {
  return !isEmpty(address)
    ? (address.street_line + " " + address.city + ", " + address.state + " " + address.zipcode).trim()
    : '';
}

export const isSmartyAddress = (v: any): v is SmartyAddress => {
  return isObject(v)
    && 'street_line' in v
    && 'city' in v
    && 'state' in v
    && 'zipcode' in v
    && 'entries' in v
    && 'secondary' in v;
};


export const entityAddressToString = (entity: SmartyAddress | Insurable | string): string => {
  if (isString(entity)) {
    return entity;
  }
  if (isSmartyAddress(entity)) {
    return smartyAddressToString(entity);
  }
  return entity?.primary_address?.full ?? '';
}
