import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, forkJoin, merge, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const BASE_URL = '/user/invoices/';

export type InvoicePaymentStatuses = Record<number, boolean | null>; // Invoice ID => boolean

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {

  constructor(private http: HttpClient) {
  }

  /**
   * Resubmits invoice
   * @param invoiceId
   */
  pay(invoiceId: number): Observable<boolean> {
    return this.http.post(`${BASE_URL}${invoiceId}/pay`, {}).pipe(
      map(_ => true),
      catchError(err => {
        console.error(err);
        return of(false);
      }),
    );
  }

  /**
   * Pays many invoices
   * @param invoiceIds
   */
  payMany(invoiceIds: Array<number>): Observable<InvoicePaymentStatuses> {
    const observables = invoiceIds.map(id => {
      return this.pay(id).pipe(
        map(paid => ({ [id]: paid })),
      );
    });
    return merge(...observables);
  }

}
