import { ISODate, YMDDate } from './dates';

export interface InvoiceModel {
  available_date: YMDDate;
  created_at: ISODate;
  updated_at: ISODate;
  due_date: YMDDate;
  id: number;
  invoiceable_id: number;
  invoiceable_type: string;
  number: string;
  status: InvoiceStatus;
  status_changed: ISODate;
  subtotal: number;
  term_first_date: string;
  term_last_date: string;
  total: number;
  total_due: number;
  was_missed: boolean;
  was_missed_at: ISODate;
  policy_type: string;
  policy_number: string;
}

export enum InvoiceStatus {
  available = 'available',
  cancelled = 'cancelled',
  complete = 'complete',
  managed_externally = 'managed_externally',
  missed = 'missed',
  pending = 'pending',
  quoted = 'quoted',
  upcoming = 'upcoming',
}
