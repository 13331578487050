<div>
  <mat-form-field fxFlex="100%" appearance="outline">
    <mat-label>{{ 'tenant_boarding.pma_carrier_autocomplete_label' | translate }}</mat-label>
    <input [formControl]="$any(form.controls.carrierSearch)"
           [matAutocomplete]="autoCarrier"
           required
           matInput
           [placeholder]="'tenant_boarding.pma_carrier_autocomplete_placeholder' | translate"
           [disabled]="form.disabled"
    />
    <mat-autocomplete #autoCarrier="matAutocomplete"
                      autoActiveFirstOption
                      (closed)="verifyCarrierName()"
                      [displayWith]="displayFnCarrier.bind(this)"
    >
      <mat-option
        *ngFor="let match of (confirmedCarrierMatches$ | async)" [value]="match.carrier"
        [class.large]="match.carrier?.synonyms?.trim().length > 0"
      >
        <div class="flex flex-col gap-0">
          <div class="caption">{{ match.carrier.title }}</div>
          <div class="same-as" *ngIf="sameAs(match) as synonym">{{ 'tenant_boarding.pma_carrier_same_as' | translate }} <span>{{ synonym }}</span></div>
        </div>
      </mat-option>
      <mat-option *ngIf="(displayedForcedCarrier$ | async) as carrier" [value]="carrier">
        {{ carrier.title }}
      </mat-option>
      <mat-option [value]="other">
        {{ other }}
      </mat-option>
    </mat-autocomplete>
    <button *ngIf="form.controls.carrierSearch.value && form.controls.carrierSearch.enabled" mat-icon-button matSuffix (click)="reset()" type="button">
      <mat-icon>close</mat-icon>
    </button>
    <button *ngIf="!form.controls.carrierSearch.value && form.controls.carrierSearch.enabled" class="text-secondary" mat-icon-button matSuffix type="button">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-error *ngIf="form.controls.carrierSearch.invalid">{{ 'tenant_boarding.select_carrier' | translate }}</mat-error>
    <mat-hint>
      {{ 'tenant_boarding.select_carrier' | translate }}

      <ng-container *brandingAttr="'carrier_field_am_best_hint'">
        {{ 'tenant_boarding.select_carrier_am_carriers' | translate }}
      </ng-container>
    </mat-hint>
  </mat-form-field>
</div>
<div *ngIf="form.controls.carrierSearch.value === other">
  <mat-form-field appearance="outline">
    <input [formControl]="$any(form.controls.otherCarrierName)"
           [placeholder]="'tenant_boarding.pma_carrier_other_input_placeholder' | translate"
           #otherInput
           matInput
           (blur)="onOtherInputBlur($event)"
    />
    <mat-hint class="similar-list">
      <ng-container *ngIf="(userEntrySimilarMatches$ | async) as similarMatches">
        <span *ngIf="similarMatches.length >= 1">
          <span>{{ 'tenant_boarding.pma_carrier_similar_label' | translate }}</span>
          <ng-container *ngFor="let match of similarMatches; index as i;">
            <button (click)="setForcedCarrier(match.carrier)">{{ match.carrier.title }}</button>
            <span>{{ i + 2 === similarMatches.length ? ' or ' : i < similarMatches.length - 1 ? ', ' : '' }}</span>
          </ng-container>
          <span>?</span>
        </span>
      </ng-container>
    </mat-hint>
  </mat-form-field>
</div>
