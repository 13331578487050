import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export interface EstimatedPremiumLead {
  monthly: number;
  period: number;
  total: number;
}

@Component({
  selector: '[app-estimated-premium]',
  templateUrl: './estimated-premium.component.html',
  styleUrls: ['./estimated-premium.component.scss']
})
export class EstimatedPremiumComponent implements OnChanges {
  @Input() total;
  @Input() period;
  @Input() loading = false;
  @Input() showPerMonth = true;

  @Output() totalChangeLead: EventEmitter<EstimatedPremiumLead> = new EventEmitter<EstimatedPremiumLead>();

  monthlyPayment;
  periodPayment;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.period && (this.total >= 0)) {
      this.monthlyPayment = this.total / 11;
      this.periodPayment = this.total / this.getDividerByPeriod(this.period);

      this.totalChangeLead.emit({
        monthly: this.monthlyPayment / 100,
        period: this.periodPayment /100,
        total: this.total / 100
      });
    }
  }

  getPeriodTranslationLabel(period) {
    return `form.${period.toLowerCase()}`;
  }

  private getDividerByPeriod(p) {
    if (!p) { return 1; }
    switch (p) {
      case 'monthly': return 11;
      case 'quarterly': return 4;
      case 'bi-annually': return 2;
      case 'annually' : return 1;
    }
  }
}
