import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '@environment';
import { FormControl, Validators } from '@angular/forms';
import { TermsPrivacyModalComponent } from '../terms-privacy-modal';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-st-elements-qbe',
  templateUrl: './st-elements-qbe.component.html',
  styleUrls: ['./st-elements-qbe.component.scss']
})
export class StElementsQbeComponent implements AfterViewInit, OnInit {
  @Input() loading: boolean;
  @Input() hasBackButton = false;
  @Input() totalPayable;
  @Output() cardComplete = new EventEmitter();
  @Output() cardFilled = new EventEmitter();
  @Output() declinePayment = new EventEmitter();
  @ViewChild('cardElement') cardElement: ElementRef;
  @ViewChild('cardNumberElement') cardNumberElement: ElementRef;
  @ViewChild('cardExpElement') cardExpElement: ElementRef;
  @ViewChild('cardCvcElement') cardCvcElement: ElementRef;
  @ViewChild('cardPostalCode') cardPostalCode: ElementRef;
  @ViewChild('paymentRequestButton') paymentRequestButton: ElementRef;
  disabledBtn = false;
  stripe; // : stripe.Stripe;
  // card;
  cardNumber;
  cardErrors;
  subscription: Subscription = new Subscription();

  paymentRequest: any;
  prButton: any;
  confirmation;

  readonly termsAgreementControl = new FormControl<boolean>(
    false, [Validators.required]
  );
  termsAgreementControlError = false;

  constructor(private translate: TranslateService, private dialog: MatDialog) {
    this.subscription.add(this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.mountStripe(params.lang);
    }));
  }

  ngOnInit(): void {
    this.termsAgreementControl.valueChanges.pipe(
      filter(checked => checked),
    ).subscribe(() => this.termsAgreementControlError = false);
  }

  ngAfterViewInit() {
    const language = this.translate.currentLang;
    this.mountStripe(language);
  }

  async mountStripe(language) {
    const style = {
      base: {
        color: '#0076bd',
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: '"Poppins", sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#a5a5a5',
        }
      }
    };

    this.stripe = Stripe(environment.stripePublishableKey, { locale: language });
    const elements = this.stripe.elements();

    this.cardNumber = elements.create('cardNumber', {
      style,
      placeholder: '**** **** **** ****',
    });
    this.cardNumber.mount(this.cardNumberElement.nativeElement);

    const cardExpiryElement = elements.create('cardExpiry', {
      style,
      placeholder: 'MM / YY',
    });
    cardExpiryElement.mount(this.cardExpElement.nativeElement);

    const cardCvcElement = elements.create('cardCvc', {
      style,
      placeholder: 'CVC',
    });
    cardCvcElement.mount(this.cardCvcElement.nativeElement);

    // this.card = elements.create('card', {style});
    // this.card.mount(this.cardElement.nativeElement);
    // this.card.addEventListener('change', ({ error }) => {
    //   this.cardErrors = error && error.message;
    // });

    this.cardNumber.addEventListener('change', (val) => {
      if (val.complete === true) {
        this.cardComplete.emit({brand: val.brand});
      }
      const error = val.error;
      this.cardErrors = error && error.message;
    });

    cardExpiryElement.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });

    cardCvcElement.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });

    this.paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Pay today',
        // NOTE: this amount is for display purposes only, the actual charge is done server-side
        amount: this.totalPayable,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      disableWallets: ['link', 'browserCard', 'googlePay'],
    });

    const result = await this.paymentRequest.canMakePayment();
    if (result && result.applePay === true) {
      this.prButton = elements.create('paymentRequestButton', {
        paymentRequest: this.paymentRequest,
        style: {
          paymentRequestButton: {
            type: 'default',
            theme: 'dark',
            height: '48px',
          },
        },
      });

      this.prButton.mount(this.paymentRequestButton.nativeElement);

      this.paymentRequest.on('paymentmethod', async (ev) => {
        if (!this.termsAgreementControl.value) {
          ev.complete('fail');
          this.termsAgreementControlError = true;
          return;
        }
        this.disabledBtn = true;
        this.cardErrors = null;

        try {
          ev.complete('success');
          this.cardFilled.emit(ev.paymentMethod);
        } catch (error) {
          ev.complete('fail');
          this.cardErrors = error.message;
        }
        this.disabledBtn = false;
      });
    } else {
      console.log('Apple Pay is not available on this device/browser.');
    }
  }

  onApplePayButtonClick() {
    this.termsAgreementControlError = true;
  }

  decline() {
    this.declinePayment.emit();
  }

  async handleForm(e) {
    this.disabledBtn = true;
    this.cardErrors = null;
    this.termsAgreementControlError = !this.termsAgreementControl.value;
    e.preventDefault();

    if (!this.termsAgreementControl.value) {
      this.disabledBtn = false;
      return;
    }

    const { error, paymentMethod } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardNumber,
      billing_details: {
        address: {
          postal_code: this.cardPostalCode.nativeElement.value
        }
      }
    });

    if (error) {
      this.cardErrors = error.message;
    } else {
      this.cardFilled.emit(paymentMethod);
    }
    this.disabledBtn = false;
  }

  /**
   * Handler for click on terms of service checkbox text since the <a> tag is in the translation file
   * @param event
   */
  onAgreementTextClick(event: MouseEvent): void {
    if (event.target instanceof HTMLElement && event.target.nodeName === 'A') {
      event.preventDefault();
      event.stopPropagation();
      let dialogRef = this.dialog.open(TermsPrivacyModalComponent, {
        maxWidth: '80vw',
        maxHeight: '80vh',
      });
    }
  }

}

