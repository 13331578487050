import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LanguageInterface, SwitchLanguagesService } from './switch-languages.service';
import { Subscription } from 'rxjs';
import { AuthService, UserService } from '../../../../core/services';
import { Router } from "@angular/router";

@Component({
  selector: 'app-switch-languages',
  templateUrl: './switch-languages.component.html',
  styleUrls: ['./switch-languages.component.scss']
})
export class SwitchLanguagesComponent implements OnInit, OnDestroy {
  @Input() fxFlex = '110px';
  @Input() styles = 'margin-right: 25px;';
  @Input() noInit = false;

  languages: LanguageInterface[] = SwitchLanguagesService.LANGUAGES;
  subscription: Subscription = new Subscription();
  currentLanguage = 'en';

  constructor(
    private translate: TranslateService,
    private switchLanguagesService: SwitchLanguagesService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if (!this.noInit) {
      this.switchLanguagesService.initCurrentLanguage();
    }
    this.currentLanguage = this.switchLanguagesService.getCurrentLanguage();
    this.subscription.add(this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      if (params.lang) {
        this.currentLanguage = params.lang;
      }
    }));
  }

  updateLanguageProfile(language) {
    const body = { profile_attributes: { language } };
    const user = this.authService.getCurrentUser();
    if (user && user.id) {
      this.userService.updateProfile(user.id, body).subscribe(result => {
        this.authService.setUser(result);
      });
    }
    this.switchLanguagesService.setCurrentLanguage(language);

    const url = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigateByUrl(url));
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
