<div class="estimate-premium__header">
  {{ 'residential.premium.estimated_header' | translate }}
</div>
<div *ngIf="!loading else loadingBlock">
  <div class="estimate-premium__p">
    {{ 'residential.premium.as_low_label' |translate }}
    <span class="estimate-premium__price">
    {{ periodPayment | stripeCurrency }}
      <ng-container *ngIf="period !== 'annually'">/ {{getPeriodTranslationLabel(period) | translate}}</ng-container>
  </span>
  </div>
  <div class="estimate-premium__hint" *ngIf="period !== 'monthly' && showPerMonth" fxHide fxShow.gt-md>
    {{ monthlyPayment | stripeCurrency }} / {{ 'residential.premium.month_label' | translate }}
  </div>
  <p class="estimate-premium__taxes">{{ 'residential.premium.estimated_hint' | translate }}</p>
</div>

<ng-template #loadingBlock>
  <div class="spinner-container">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</ng-template>

