import { Directive, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appRequiredStar]'
})
export class RequiredStarDirective {
  private formControl;
  @Input()
  public get forLabelForm(): AbstractControl {
    return this.formControl;
  }

  public set forLabelForm(val: AbstractControl) {
    this.formControl = val;
    if (this.hasRequiredField(val)) {
      this.requiredLabel = true;
    }
  }

  @HostBinding('class.required-label')
  public requiredLabel = false;

  constructor() { }

  private hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({}as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }
}
