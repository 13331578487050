import { Component, Input, OnInit } from '@angular/core';
import { BrandingService } from '../../core/services/branding.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';

interface TabItem {
  type: string;
  description: string;
  routerLink: string;
  originLink: string;
}

@Component({
  selector: 'app-tab-switcher',
  templateUrl: './tab-switcher.component.html',
  styleUrls: ['./tab-switcher.component.scss']
})
export class TabSwitcherComponent implements OnInit {
  tabItems: TabItem[];

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private brandingService: BrandingService
  ) { }

  ngOnInit() {
    this.getAvailableTabs();
  }

  goToTab(tab) {
    this.router.navigateByUrl(tab.routerLink);
  }

  getWidth() {
    if (this.tabItems.length === 1) { return '50%'; } else { return `${Math.round(10000 / this.tabItems.length) / 100}%`; }
  }

  getActiveTab() {
    const tabItem =  this.tabItems.find(v => v.originLink === window.location.pathname);
    return tabItem ? tabItem.type : 'residential';
  }

  private getAvailableTabs() {
    combineLatest([
      this.brandingService.residentialFormAvailable$,
      this.brandingService.commercialFormAvailable$,
      this.brandingService.rentGuaranteeFormAvailable$
    ]).pipe(
    ).subscribe(([residential, commercial, rentGuarantee]) => {
      const result = [];
      if (rentGuarantee) {
        result.push({
          type: 'guarantee',
          description: 'Rent Guarantee',
          originLink: '/rentguarantee',
          routerLink: '/rentguarantee'
        });
      }
      if (residential) {
        result.push({
          type: 'residential',
          description: 'Renters Insurance',
          originLink: '/residential',
          routerLink: '/residential'
        });
      }
      if (commercial) {
        result.push({
          type: 'commercial',
          description: 'Business Owner Policy',
          originLink: '/commercial',
          routerLink: '/commercial'
        });
      }
      this.tabItems = result;
    });
  }
}
