<div class="residential-tabs-block">
  <ng-container *ngFor="let tab of tabItems">
    <label class="residential-tab" (click)="goToTab(tab)"
           [ngClass]="{'selected-registration-method': getActiveTab() === tab.type}"
           [style.width]="getWidth()">
      <p><a href="javascript:void(0)">{{tab.description}}</a></p>
      <input type="radio" id="residential" name="selectedTab" checked>
    </label>
  </ng-container>
</div>
