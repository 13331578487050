import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toTitlecase' })
export class ToTitlecasePipe implements PipeTransform {
  transform(value: string): string {
    return(value ? value
          .replace(/([A-Z])/g, ' $1')
          .replace('_', ' ')
          .replace('-', ' ')
          .replace(/(^| )(\w)/g, s => s.toUpperCase())
        : value
    );
  }
}
