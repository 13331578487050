import { Directive, ElementRef, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounce } from 'lodash';

@Injectable({
  providedIn: 'root',
})
class InfiniteScrollService {

  private readonly _directives = new Set<InfiniteScrollDirective>();

  private _listener: () => void;

  public add(dir: InfiniteScrollDirective, scrollCallback: () => void): void {
    this._directives.add(dir);
    if (!this._listener) {
      let prevY = window.scrollY;
      this._listener = debounce(() => {
        const rect = dir.element.getBoundingClientRect();
        const diff = rect.bottom - window.innerHeight + dir.infiniteScroll.margin;
        if (prevY < window.scrollY && diff <= 0) {
          scrollCallback();
        }
        prevY = window.scrollY;
      }, 100);
      window.addEventListener('scroll', this._listener);
    }
  }

  public remove(dir: InfiniteScrollDirective): void {
    this._directives.delete(dir);
    if (this._directives.size === 0) {
      window.removeEventListener('scroll', this._listener);
    }
  }

}

@Directive({
  selector: '[infiniteScroll], [infinite-scroll]',
})
export class InfiniteScrollDirective implements OnDestroy, OnInit {

  @Input() infiniteScroll = { margin: 100 };

  @Output() readonly loadMore = new EventEmitter<boolean>();

  constructor(private el: ElementRef, private service: InfiniteScrollService) {
  }

  get element(): HTMLElement {
    return this.el.nativeElement;
  }

  public ngOnInit(): void {
    this.service.add(this, () => {
      this.loadMore.emit(true);
    });
  }

  public ngOnDestroy(): void {
    this.service.remove(this);
  }
}
